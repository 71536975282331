import { Field, Form, Formik } from "formik";
import { PersistFormikValues } from "formik-persist-values";
import { useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { HouseDoorFill, TelephoneFill } from "styled-icons/bootstrap";
import { BuildingBank, Mail } from "styled-icons/fluentui-system-filled";
import { sendMail } from "./firebase";
import MessageSend from "./MessageSend";

interface Values {
  name: string;
  email: string;
  message: string;
}

const Contact = () => {
  const initialValues: Values = { name: "", email: "", message: "" };
  const [showAlert, setShowAlert] = useState(false);

  return (
    <>
      <div className="mt-3 flex flex-col items-center gap-6">
        <ul className="flex flex-col items-center gap-2 text-xl">
          <li>
            <a href="mailto:beratung.klotz.brenner@gmx.net" className="flex justify-between gap-3">
              <Mail className="w-6" />
              beratung.klotz.brenner@gmx.net
            </a>
          </li>
          <li>
            <a href="tel:+4915151936485" className="flex justify-between gap-3">
              <TelephoneFill className="w-6" />
              +49 151 51936485
            </a>
          </li>
          <li>
            <a
              href="https://goo.gl/maps/KpSyFvVN3ainqKon8"
              target="_blank"
              rel="noreferrer"
              className="flex justify-between gap-3"
            >
              <HouseDoorFill className="w-6" />
              Hafnerweg 1, 89231 Neu-Ulm
            </a>
          </li>
          <li>
            <span className="flex justify-between gap-3">
              <BuildingBank className="w-6" />
              DE88 1203 0000 1204 5467 80
            </span>
          </li>
        </ul>
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            await sendMail(values);
            setSubmitting(false);
            setShowAlert(true);
            resetForm();
          }}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form className="flex w-full max-w-2xl flex-col items-stretch gap-2">
              <Field
                type="text"
                name="name"
                placeholder="Ihr Name"
                required
                className="focus:border-sky focus:ring-sky h-full resize-none rounded-lg border border-stone-600 bg-stone-100 shadow-sm focus:ring-1"
                disabled={isSubmitting}
              />
              <Field
                type="email"
                name="email"
                placeholder="Ihre E-Mail-Adresse"
                required
                className="focus:border-sky focus:ring-sky h-full resize-none rounded-lg border border-stone-600 bg-stone-100 shadow-sm focus:ring-1"
                disabled={isSubmitting}
              />
              <Field
                as={TextareaAutosize}
                name="message"
                placeholder="Ihre Nachricht"
                required
                minRows={6}
                className="focus:border-sky focus:ring-sky h-full resize-none rounded-lg border border-stone-600 bg-stone-100 shadow-sm focus:ring-1"
                disabled={isSubmitting}
              />
              <button
                type="submit"
                className="focus:ring-sky focus:border-sky mx-auto w-32 rounded-lg border border-stone-600 bg-stone-100 py-2 outline-0 hover:bg-stone-200 focus:ring-1 disabled:contrast-50"
                disabled={isSubmitting}
              >
                Senden
              </button>
              <PersistFormikValues name="contact-form" />
            </Form>
          )}
        </Formik>
        <div className="">
          <p>
            Eine Gesprächseinheit dauert in der Regel 90 Minuten bei einem Honorar von 80&nbsp;€ für ein Einzelgespräch
            und 90&nbsp;€ für ein Paargespräch. Für Auszubildende und Studenten gibt es eine Ermäßigung.
          </p>
        </div>
      </div>
      {showAlert && <MessageSend close={() => setShowAlert(false)} />}
    </>
  );
};

export default Contact;
